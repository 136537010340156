const menu_button_el = document.querySelector('.menu-button');
const header_menu_el = document.querySelector('.header-menu');
const header_menu_top_el = document.querySelector('.header-menu-top');
const header_menu_block_el = document.querySelector('.header-menu-block');

header_menu_el?.insertAdjacentHTML('beforeend', `
    <div class="header-menu__backdrop"></div>
    
    <div class="header-menu__close">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M24.9441 8.94216C25.4648 8.42146 25.4648 7.57724 24.9441 7.05654C24.4234 6.53584 23.5792 6.53584 23.0585 7.05654L16.0013 14.1137L8.94411 7.05654C8.42341 6.53584 7.57919 6.53584 7.05849 7.05654C6.53779 7.57724 6.53779 8.42146 7.05849 8.94216L14.1157 15.9993L7.05849 23.0565C6.53779 23.5772 6.53779 24.4215 7.05849 24.9422C7.57919 25.4629 8.42341 25.4629 8.94411 24.9422L16.0013 17.885L23.0585 24.9422C23.5792 25.4629 24.4234 25.4629 24.9441 24.9422C25.4648 24.4215 25.4648 23.5772 24.9441 23.0565L17.8869 15.9993L24.9441 8.94216Z" fill="#131625"/>
        </svg>
    </div>
`);

header_menu_block_el?.querySelectorAll('li.menu-item-has-children > a').forEach(item => {
    const button_str = `
            <span class="submenu__button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289Z" fill="#F8F8F8"/>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289Z" fill="#F8F8F8"/>
                </svg>
            </span>
        `;

    item.insertAdjacentHTML('afterend', button_str);
});

menu_button_el?.addEventListener('click', function () {
    header_menu_el.classList.add('active');
});

document.body.addEventListener('click', function(event) {
    if (event.target.classList.contains('header-menu__backdrop') || event.target.classList.contains('close-button') || event.target.closest('.close-button') || event.target.classList.contains('.header-menu__close') || event.target.closest('.header-menu__close')) {
        header_menu_el.classList.remove('active');
        header_menu_top_el.style.left = 0;
        header_menu_el.querySelectorAll('ul.sub-menu').forEach(item => {
            item.classList.remove('active');
            item.style.left = '-100vw';
            item.querySelector('.submenu__parent')?.remove();
        });
    }
    else if (event.target.classList.contains('menu-item-has-children') || (!event.target.closest('.sub-menu') && event.target.closest('.menu-item-has-children'))) {
        event.preventDefault();
        event.stopPropagation();

        const parent_el = event.target.closest('li');
        const submenu_el = parent_el.querySelector('ul.sub-menu');
        const parent_link_el = parent_el.querySelector('a');

        header_menu_el.querySelectorAll('ul.sub-menu').forEach(item => {
            if (item !== submenu_el) {
                item.classList.remove('active');
                item.style.left = '-100vw';
                item.querySelector('.submenu__parent')?.remove();
            }
        });

        submenu_el.classList.toggle('active');

        // if (submenu_el.classList.contains('active')) {
            let ml_width = `${header_menu_block_el.getBoundingClientRect().width}px`;

            if (window.innerWidth < 830) {
                ml_width = `${header_menu_block_el.getBoundingClientRect().width + 30}px`;
            }
            else if (window.innerWidth >= 830 && window.innerWidth < 1400) {
                ml_width = `100vw`;
            }

            submenu_el.style.left = ml_width;

            if (window.innerWidth < 1400) {
                header_menu_top_el.style.left = `-${ml_width}`;
            }

            submenu_el.insertAdjacentHTML('afterbegin', `
                <li class="submenu__parent" href="https://localhost/blog/">

                    <div class="submenu__parent__svgs">
                        <svg class="submenu__parent__svgs__one" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 6.70711C11.0976 6.31658 11.0976 5.68342 10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289L3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L9.29289 18.7071C9.68342 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41421 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41421 11L10.7071 6.70711Z" fill="#F8F8F8"></path>
                        </svg>

                        <svg class="submenu__parent__svgs__two" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 6.70711C11.0976 6.31658 11.0976 5.68342 10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289L3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L9.29289 18.7071C9.68342 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41421 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41421 11L10.7071 6.70711Z"></path>
                        </svg>
                    </div>

                    <span>${parent_link_el.textContent}</span>
                </li>
                
            `);
        // }
        // else {
        //     submenu_el.style.left = window.innerWidth < 1400 ? '-100vw' : '-100px';
        //     let submenu_parent = submenu_el.querySelector('.submenu__parent');
        //     if (submenu_parent) {
        //         submenu_parent.remove();
        //     }
            
        //     header_menu_top_el.style.left = 0;
        // }
    }
    else if (event.target.classList.contains('submenu__parent') || event.target.closest('.submenu__parent')) {
        const submenu_el = event.target.closest('ul.sub-menu');
        submenu_el.style.left = window.innerWidth < 1400 ? '-100vw' : '-100px';
        submenu_el.querySelector('.submenu__parent').remove();
        header_menu_top_el.style.left = 0;
    }
});

document.querySelectorAll('.lang-block__top, .lang-block__backdrop').forEach(item => item.addEventListener('click', function () {
    const parent_el = item.closest('.lang-block');
    const select_el = parent_el.querySelector('.lang-block__select');

    parent_el.classList.toggle('active');

    if (parent_el.classList.contains('active')) {
        let totalElHeight = 0;
        select_el.querySelectorAll('a').forEach(item => totalElHeight+=item.getBoundingClientRect().height);

        select_el.style.height = `${totalElHeight}px`;
    }
    else {
        select_el.style.height = 0;
    }
}));