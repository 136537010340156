import {getCookie, setCookie} from "../functions";

document.querySelectorAll('.location-preview').forEach((item, index) => item.addEventListener('mouseenter', function () {
    document.querySelectorAll('.location-preview').forEach(subitem => {
        subitem.classList.remove('active');
        subitem.classList.add('shadow-enabled');
    });
    item.classList.add('active');
    item.classList.remove('shadow-enabled');
}));

document.querySelectorAll('.location-preview').forEach(item => item.addEventListener('mouseleave', function () {
    document.querySelectorAll('.location-preview').forEach(subitem => {
        subitem.classList.remove('active');
        subitem.classList.remove('shadow-enabled');
    });
    document.querySelectorAll('.location-preview')[0].classList.add('active');
}));

document.querySelectorAll('[data-location]').forEach(item => item.addEventListener('click', function () {
    if (item.classList.contains('active'))
        return;

    setCookie('location', encodeURIComponent(item.getAttribute('data-location')), '7');
    window.location.reload();
}));

window.addEventListener('load', function () {
    if (!getCookie('location')) {
        setCookie('location', document.querySelector('.cities-block-item.active').getAttribute('data-location'), '7');
    }
})