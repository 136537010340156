document.querySelectorAll('.block-video__button').forEach(item => item.addEventListener('click', function () {
    const parent_el = this.closest('.block-video');
    const wrapper_el = this.closest('.block-video__wrapper');

    if (parent_el.querySelector('video')) {
        parent_el.querySelector('video').play();
        parent_el.querySelector('video').setAttribute('controls', 'true');
    }
    else {
        const iframe = parent_el.querySelector('iframe');

        const player = new YT.Player(iframe, {
            events: {
                onReady: function(event) {
                    event.target.playVideo();
                },
                onStateChange: function (event) {
                    if (event.data === YT.PlayerState.PLAYING) {
                        parent_el.classList.add('block-video--active');
                    }
                }
            }
        });
    }
    wrapper_el.classList.add('block-video__wrapper--fixed');
}));