import 'perfect-scrollbar/css/perfect-scrollbar.css';
import PerfectScrollbar from 'perfect-scrollbar';
import {isSafari} from "../functions";

document.addEventListener('DOMContentLoaded', () => {
    const scrollableElements = document.querySelectorAll('.scrollable-list');
    scrollableElements.forEach(element => {
        const ps = new PerfectScrollbar(element, {
            wheelSpeed: 0.21,
            wheelPropagation: false,
            minScrollbarLength: 20,
            suppressScrollX: true
        });
    });

    if (isSafari())
        document.querySelectorAll('.attachment-section__image').forEach(item => item.classList.add('attachment-section__image--safari'));
});

const sliderElementFirst = document.querySelector('.swiper');

if (sliderElementFirst) {
    function initSliderScript() {
        const script = document.createElement('script');
        script.src = document.querySelector('[name="slides_init_script_src"]').value;
        document.body.appendChild(script);
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                initSliderScript();
                observer.unobserve(sliderElementFirst);
            }
        });
    }, {
        root: null,
        rootMargin: '500px 0px'
    });

    observer.observe(sliderElementFirst);
}

console.log('test');