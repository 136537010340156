// document.body.addEventListener('click', function(event) {
//     if (event.target.classList.contains('button--more') || event.target.closest('.button--more')) {
//         const button_el = event.target.classList.contains('button--more') ? event.target : event.target.closest('.button--more');
//         button_el.setAttribute('disabled', 'true');
//
//         axios.get(api_settings.ajax_url, {
//             params: {
//                 nonce: api_settings.nonce,
//                 action: 'update_blog',
//                 paged: button_el.getAttribute('data-next-page'),
//             }
//         })
//             .then(function (response) {
//                 if (!response.data.is_next_page) {
//                     button_el.style.display = 'none';
//                 }
//                 else {
//                     button_el.setAttribute('data-next-page', response.data.next_page);
//                 }
//
//                 document.querySelector('#html_posts').insertAdjacentHTML('beforeend', response.data.posts_html);
//
//                 const path = window.location.pathname;
//                 const segments = path.split('/');
//                 const slug = segments[1];
//
//                 history.pushState(null, '', `/${slug}/page/${button_el.getAttribute('data-next-page')}/`);
//
//                 button_el.removeAttribute('disabled');
//             })
//             .catch(function (error) {
//                 console.log(error);
//                 button_el.removeAttribute('disabled');
//             })
//     }
// });

document.body.addEventListener('click', function (event) {
    if (event.target.classList.contains('block-more__button') || event.target.closest('.block-more__button')) {
        const item = event.target.classList.contains('block-more__button') ? event.target : event.target.closest('.block-more__button');
        const parent_el = item.closest('.block-more');
        const content_el = parent_el.querySelector('.block-more__child');
        const content_hidden_el = parent_el.querySelector('.block-more__text');

        content_el.classList.toggle('active');
        content_hidden_el.style.maxHeight = content_el.classList.contains('active') ? `${content_hidden_el.scrollHeight}px` : 0;
        item.textContent = content_el.classList.contains('active') ? item.getAttribute('data-less') : item.getAttribute('data-more');

        // Если блок сворачивается, выполняем скролл к родительскому элементу
        if (!content_el.classList.contains('active')) {
            parent_el.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }
});


const parent_content = document.querySelector('.content');

if (parent_content) {
    const moreElement = parent_content.querySelector('span[id^="more-"]');

    if (moreElement) {
        const button_more_text_show = document.querySelector('input[name="button_more_text_show"]').value;
        const button_more_text_hide = document.querySelector('input[name="button_more_text_hide"]').value;

        const prevElements = [];

        let child = parent_content.firstElementChild;

        while (child && child !== moreElement) {
            prevElements.push(child);
            child = child.nextElementSibling;
        }

        const prevBlockParent = document.createElement('div');
        prevBlockParent.classList.add('block-more__child');
        prevBlockParent.classList.add('content');
        prevElements.forEach(element => {
            prevBlockParent.appendChild(element);
        });
        parent_content.prepend(prevBlockParent);


        const nextElements = [];
        let nextSibling = moreElement.nextElementSibling;

        while (nextSibling) {
            nextElements.push(nextSibling);
            nextSibling = nextSibling.nextElementSibling;
        }

        nextElements.forEach(element => element.remove());

        const nextBlockParent = document.createElement('div');
        nextBlockParent.classList.add('block-more__text');
        nextBlockParent.classList.add('content');
        nextBlockParent.style.maxHeight = 0;
        nextElements.forEach(element => nextBlockParent.appendChild(element));
        parent_content.appendChild(nextBlockParent);


        const buttonParentEl = `
        <div class="block-more__button--wrapper">
            <button class="block-more__button" data-more="${button_more_text_show}" data-less="${button_more_text_hide}">${button_more_text_show}</button>
        </div>
        `;
        parent_content.insertAdjacentHTML('beforeend', buttonParentEl);


        parent_content.classList.add('block-more');
        moreElement.remove();
    }
}